import React, { PropsWithChildren } from "react";

export type Process = {
  uploaded: boolean;
  converted: boolean;
  downloaded: boolean;
};
export interface IAppContext {
  process: Process;
  setProcess: React.Dispatch<React.SetStateAction<Process>>;
  setUploaded: (uploaded: boolean) => void;
  setConverted: (converted: boolean) => void;
  setDownloaded: (downloaded: boolean) => void;
}
export const placeholderContext: IAppContext = {
  process: { uploaded: false, converted: false, downloaded: false },
  setProcess: () => {},
  setUploaded: () => {},
  setConverted: () => {},
  setDownloaded: () => {},
};
export const AppContextC = React.createContext<IAppContext>(placeholderContext);
AppContextC.displayName = "App Context";

const AppContext: React.FC<PropsWithChildren> = ({ children }) => {
  const [process, setProcess] = React.useState<Process>({
    uploaded: false,
    converted: false,
    downloaded: false,
  });
  const set = React.useCallback((key: keyof Process) => {
    return (newValue: boolean) => {
      setProcess((prev) => ({ ...prev, [key]: newValue }));
    };
  }, []);
  return (
    <AppContextC.Provider
      value={{
        process,
        setProcess,
        setUploaded: set("uploaded"),
        setConverted: set("converted"),
        setDownloaded: set("downloaded"),
      }}
    >
      {children}
    </AppContextC.Provider>
  );
};

export default AppContext;

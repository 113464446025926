import axios from "axios";

const baseURL = "https://imi-dev.imi.kit.edu:81";
// const baseURL = "http://localhost:1080";

export async function upload(file: File) {
  if (file === null) {
    return;
  }
  return axios.post(
    `${baseURL}/web/headlessDemo/upload.php?file=${encodeURIComponent(file.name)}`,
    file,
    {
      headers: {
        "Content-Type": "application/vnd.step",
      },
    }
  );
}
export function getGLTFLocation(fileName: string) {
  const fileNameEncoded = fileName
    .split(/\//)
    .map((e) => encodeURIComponent(e))
    .join("/");
  return `${baseURL}/web/headlessDemo/${fileNameEncoded}`;
}
export enum ConversionStepResult {
  Error,
  Success,
  Failure,
}
export function triggerConversion(
  fileName: string
): Promise<[ConversionStepResult, string]> {
  const subparts = false;
  const relativeDeflection = true;
  const lDeflection = 0.1;
  const aDeflection = 0.5;
  const url = `${baseURL}/web/headlessDemo/convertStep.php?file=${encodeURIComponent(fileName)}&subparts=${subparts}&relativeDeflection=${relativeDeflection}&lDeflection=${lDeflection}&aDeflection=${aDeflection}`;
  return axios
    .get(url)
    .then((response) => {
      const fileNameResponse = response.data.match(
        /received: convertion_ready\|(.*)\|/
      );
      if (!fileNameResponse || fileNameResponse.length < 2) {
        return [ConversionStepResult.Error, "Could not parse response"] as [
          ConversionStepResult,
          string,
        ];
      }
      return (
        response.status === 200
          ? [ConversionStepResult.Success, fileNameResponse[1]]
          : [ConversionStepResult.Failure, ""]
      ) as [ConversionStepResult, string];
    })
    .catch((e) => [ConversionStepResult.Error, e.toString()]);
}

import React from "react";
import HourglassBottomIcon from "@mui/icons-material/HourglassBottom";
import DoneIcon from "@mui/icons-material/Done";
import { AppContextC, Process } from "../api/context/AppContext";
type StepProps = {
  title: string;
  processKey: keyof Process;
};
const Step: React.FC<StepProps> = ({ title, processKey }) => {
  const done = React.useContext(AppContextC)?.process[processKey] ?? false;
  return (
    <li>
      {!done && <HourglassBottomIcon />}
      {done && <DoneIcon />}

      {title}
    </li>
  );
};
export default Step;

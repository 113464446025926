import { Container } from "@mui/system";
import React from "react";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import { Divider, Stack, Typography } from "@mui/material";
import styled from "@emotion/styled";
import ViewerWrapper from "./ViewerWrapper";
import Step from "./Step";
import AppContext from "../api/context/AppContext";

type MainProps = Record<string, never>;

const ViewerContainer = styled("div")({
  borderStyle: "dashed dashed dashed none",
  borderRadius: "10px",
  borderWidth: "1px",
  borderColor: "lightgrey",
  height: "100%",
  width: "70%",
});
const DescriptionContainer = styled("div")({
  width: "30%",
  height: "100%",
  borderStyle: "dashed none dashed dashed",
  borderRadius: "10px",
  borderWidth: "1px",
  borderColor: "lightgrey",
});
const Main: React.FC<MainProps> = (props) => {
  return (
    <AppContext>
      <Container>
        <Stack justifyContent="center" alignItems="center" height={"100vh"}>
          <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            height={500}
            width={"100%"}
            divider={<Divider orientation="vertical" flexItem />}
          >
            <DescriptionContainer>
              <Typography variant="h2">STP Viewer</Typography>
              <Typography variant="body1">
                Please upload a file, then, wait...
              </Typography>
              <ul>
                <Step title="Upload file" processKey={"uploaded"} />
                <Step
                  title="Convert stp &rarr; gltf"
                  processKey={"converted"}
                />
                <Step title="Load GLTF in Viewer" processKey={"downloaded"} />
              </ul>
            </DescriptionContainer>
            <ViewerContainer>
              <ViewerWrapper />
            </ViewerContainer>
          </Stack>
        </Stack>
      </Container>
    </AppContext>
  );
};
export default Main;

import { Button } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import React from "react";
import styled from "@emotion/styled";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

type UploadButtonProps = {
  onUpload: (file: File) => void;
};
const UploadButton: React.FC<UploadButtonProps> = ({ onUpload }) => {
  const handleFile = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.files) {
        onUpload(e.target.files[0]);
      }
    },
    [onUpload],
  );
  return (
    <Button
      component="label"
      variant="contained"
      startIcon={<CloudUploadIcon />}
      sx={{
        position: "relative",
        top: "calc(50% - 36px / 2)",
        left: "calc(50% - 144px / 2)",
        zIndex: 1,
      }}
    >
      Upload file
      <VisuallyHiddenInput type="file" accept=".stp" onChange={handleFile} />
    </Button>
  );
};
export default UploadButton;

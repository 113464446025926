import { CircularProgress } from "@mui/material";
import React from "react";
import { AppContextC } from "../api/context/AppContext";
import {
  ConversionStepResult,
  getGLTFLocation,
  triggerConversion,
  upload,
} from "../api/Kit";
import UploadButton from "./UploadButton";

import Viewer from "@motionray/viewer/dist/components/Viewer";
import { Part } from "@motionray/viewer/dist/model/Codecs";
const pseudoPart: Part = {
  id: "MR1ee3ff33f0405941de55aaafac16bfb365af599b0f792455717601#SeiteLinks",
  name: "SeiteLinks",
  hash: "6b825e2cf8bddae1e713a5b75695a093",
  opacity: 1,
  position: { x: 0, y: 0, z: 0 },
  rotation: [
    [1, 0, 0],
    [0, 1, 0],
    [0, 0, 1],
  ],
  boundingBox: {
    dimension: {
      width: 0,
      height: 0,
      depth: 0,
    },
  },
  visualisation: "",
};

enum ViewerState {
  LOADING,
  INITIALIZED,
  LOADED,
}
const ViewerWrapper: React.FC = () => {
  const [file, setFile] = React.useState<File | null>(null);
  const [convertedFileName, setConvertedFileName] = React.useState<string>("");
  const [state, setState] = React.useState<ViewerState>(
    ViewerState.INITIALIZED,
  );

  const { setDownloaded, setUploaded, setConverted } =
    React.useContext(AppContextC);

  React.useEffect(() => {
    if (file === null) {
      return;
    }
    console.warn("Entering here");
    upload(file)
      .then(() => setUploaded(true))
      .then(() => triggerConversion(file.name))
      .then(([result, fileNameOrError]) => {
        if (result === ConversionStepResult.Success) {
          setConverted(true);
          setConvertedFileName(fileNameOrError);
          setState(ViewerState.LOADED);
          setDownloaded(true);
        } else {
          console.warn("Failed:", fileNameOrError);
        }
      });
  }, [file]);
  const handleOnUpload = React.useCallback((file: File) => {
    setState(ViewerState.LOADING);
    setFile(file);
  }, []);
  switch (state) {
    case ViewerState.LOADING:
      return (
        <CircularProgress
          size={68}
          sx={{
            position: "relative",
            top: "calc(50% - 68px / 2)",
            left: "calc(50% - 68px / 2)",
            zIndex: 1,
          }}
        />
      );
    case ViewerState.INITIALIZED:
      return <UploadButton onUpload={handleOnUpload} />;
    case ViewerState.LOADED:
      return (
        <Viewer
          parts={[
            {
              ...pseudoPart,
              visualisation: getGLTFLocation(convertedFileName),
            },
          ]}
          infoPanelContent={() => <div>Info Panel</div>}
          boundingBox={pseudoPart.boundingBox}
          transparencyPanel={() => <span />}
        />
      );
    default:
      return <div>"not implemented"</div>;
  }
};
ViewerWrapper.displayName = "Viewer Wrapper";
ViewerWrapper.whyDidYouRender = true;
export default ViewerWrapper;
